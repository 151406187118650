@tailwind base;
@tailwind components;
@tailwind utilities;
@variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
  
@layer components {
    .profile {
        @apply flex flex-col justify-center flex-wrap content-center items-center gap-10;
    }
    #profile-image {
        @apply w-[130px] h-[130px] rounded-full;
    }

    body {
        @apply bg-site-bg w-auto bg-cover bg-center bg-no-repeat bg-fixed ;
    }

    .main-body {
        @apply flex items-center justify-center flex-col gap-4  backdrop-blur-sm rounded-2xl p-10;
    }
    
    .social-media-bar ul li img {
        @apply w-10 h-10 rounded-full;
    }
    

    .social-media-bar ul li {
        @apply inline-block ml-6 mr-6 hover:scale-110 transition duration-100 ease-linear;
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    .test {
        @apply h-full overflow-auto w-[90%] relative flex items-center justify-center;
    }
}
